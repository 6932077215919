import joi from "joi";
import { useEffect, useState } from "react";
import { Card, Form, Loader } from "@rigly/core";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useAuthContext } from "../context/AuthContext";
import { updateTeam } from "../api/team";
import toast from "react-hot-toast";
import View from "../components/View";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../constants";

export default function SettingsRoute() {
  const { loading, team } = useAuthContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState(undefined);

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true);
      await updateTeam(team.id, data);
      toast.success("Account updated", { position: "bottom-right" });
    } catch (ex: any) {
      console.log(ex);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (team) {
      setValues({ ...team });
    }
  }, [team]);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Settings</title>
      </Helmet>
      <Topbar />
      <div className="flex h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Card className="p-4 bg-radial-gradient-dark dark:bg-radial-gradient-light">
            {loading && (
              <div className="flex items-center justify-center">
                <Loader className="text-white w-8 h-8" />
              </div>
            )}

            {!loading && !values && (
              <div className="flex items-center justify-center py-10">
                <span className="text-sm text-white">
                  Error: Could not load team
                </span>
              </div>
            )}

            {!loading && values && (
              <Form
                schema={[
                  {
                    type: "text",
                    id: "payout_address",
                    description: "Your BTC address for payouts",
                    label: "Payout address",
                    placeholder: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    required: true,
                    schema: joi
                      .string()
                      .required()
                      .error(new Error("Payout address is required")),
                  },
                  { type: "submit", id: "submit", label: "Save" },
                ]}
                values={values}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            )}
          </Card>
        </View>
      </div>
    </div>
  );
}
