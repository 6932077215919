export function parsePoolUrl(input: string) {
  const data = input.split("://");
  if (data.length === 2) {
    const location = data[1].split(":");
    const host = location[0];
    const port = Number(location[1]);
    return {
      protocol: data[0],
      host: host,
      port: port,
      location: `${host}:${port}`,
    };
  }

  const location = data[0].split(":");
  const host = location[0];
  const port = Number(location[1]);

  return {
    protocol: undefined,
    host: host,
    port: port,
    location: `${host}:${port}`,
  };
}
