import clsx from "clsx";

function Modal({ children, visible }: { children: any; visible: boolean }) {
  return (
    <div
      className={clsx("absolute inset-0 flex items-center justify-center", {
        visible: visible,
        hidden: !visible,
      })}
    >
      <div className="bg-zinc-900 rounded border border-zinc-700 text-white">
        {children}
      </div>
    </div>
  );
}

Modal.Header = function ({ children }: { children: any }) {
  return (
    <div className="px-3 h-10 flex items-center justify-between border-b border-zinc-700 gap-4">
      {children}
    </div>
  );
};

Modal.Body = function ({ children }: { children: any }) {
  return <div>{children}</div>;
};

Modal.Footer = function ({ children }: { children: any }) {
  return (
    <div className="px-3 h-10 flex items-center justify-between border-t border-zinc-700">
      {children}
    </div>
  );
};

export default Modal;
