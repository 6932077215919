export function set(key: string, value: any) {
  window.localStorage.setItem(key, value);
}

export function get(key: string) {
  const value = window.localStorage.getItem(key);
  if (!value) {
    return undefined;
  }
  return value;
}
