import joi from "joi";
import { useState } from "react";
import toast from "react-hot-toast";
import { Card, Form, Loader } from "@rigly/core";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { useAuthContext } from "../context/AuthContext";
import { Helmet } from "react-helmet";
import { APP_NAME, BASE_PORTAL_URL } from "../constants";

export default function LoginRoute() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { loading, token, login } = useAuthContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const handleSubmit = async (data: any) => {
    try {
      setIsSubmitting(true);
      const res = await login(data.email, BASE_PORTAL_URL);
      setMessage(res.message);
      navigate("/login?success=true");
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-right" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSuccessPage = searchParams.get("success") === "true";

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loader className="text-white" />
      </div>
    );
  }

  if (!token) {
    if (isSuccessPage && !message) {
      return <Navigate to="/login" replace={true} />;
    }

    return (
      <div className="flex flex-col">
        <Helmet>
          <title>{APP_NAME} - Sign In</title>
        </Helmet>
        <div className="h-screen flex items-center justify-center w-full p-4">
          {!isSuccessPage && (
            <Card className="p-4 flex flex-col w-full mx-auto sm:w-6/12 xl:w-4/12 gap-4 bg-radial-gradient-dark dark:bg-radial-gradient-light">
              <div className="flex flex-col gap-2">
                <h1 className="font-bold text-xl text-zinc-500 dark:text-white">
                  Sign in
                </h1>
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  Sign in to your Rigly seller account with your email
                </p>
              </div>
              <Form
                schema={[
                  {
                    type: "email",
                    id: "email",
                    placeholder: "satoshi@gmx.com",
                    required: true,
                    schema: joi
                      .string()
                      .email({ tlds: { allow: false } })
                      .required()
                      .error(new Error("E-mail is required")),
                  },
                  { type: "submit", id: "submit", label: "Sign In" },
                ]}
                values={[{ email: "" }]}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            </Card>
          )}
          {isSuccessPage && (
            <Card className="p-4 flex flex-col w-2/5 gap-4 bg-radial-gradient-dark dark:bg-radial-gradient-light">
              <div className="flex flex-col gap-2">
                <h1 className="font-bold text-xl text-white">Sign in</h1>
                <p className="text-sm text-zinc-400">{message}</p>
              </div>
            </Card>
          )}
        </div>
      </div>
    );
  }

  return <Navigate to="/" replace={true} />;
}
