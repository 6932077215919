import clsx from "clsx";

export default function View({
  className,
  children,
}: {
  className?: string;
  children: any;
}) {
  return (
    <div
      className={clsx(
        className,
        "w-screen grid grid-cols-[200px_1fr] bg-radial-gradient-dark dark:bg-radial-gradient-light"
      )}
    >
      {children}
    </div>
  );
}
