import { Card } from "@rigly/core";
import clsx from "clsx";

export default function Toolbar({
  children,
  className = "mb-2",
}: {
  children?: any;
  className?: string;
}) {
  return (
    <Card
      className={clsx(
        "w-full flex !flex-row items-center justify-between !p-2",
        className
      )}
    >
      {children}
    </Card>
  );
}
