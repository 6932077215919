import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import DashboardRoute from "./routes/Dashboard";
import SettingsRoute from "./routes/Settings";
import StatisticsOverviewRoute from "./routes/statistics/Overview";
import StatisticsEpochRoute from "./routes/statistics/Epoch";
import StatisticsDateRoute from "./routes/statistics/Date";
import LoginRoute from "./routes/Login";
import LoginCallbackRoute from "./routes/LoginCallback";
import TransactionsRoute from "./routes/Transactions";
import RevenueRoute from "./routes/Revenue";
import ProxiesRoute from "./routes/Proxies";
import ProxyDetailRoute from "./routes/ProxyDetail";

import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "./context/ThemeContext";
import { Toaster } from "react-hot-toast";

function App() {
  // useHighlight();

  return (
    <>
      <Router>
        <AuthProvider>
          <ThemeProvider>
            <Layout>
              <Routes>
                <Route index element={<DashboardRoute />} />
                <Route path="/settings" element={<SettingsRoute />} />
                <Route
                  path="/revenue/:epoch/:date"
                  element={<StatisticsDateRoute />}
                />
                <Route
                  path="/revenue/:epoch"
                  element={<StatisticsEpochRoute />}
                />
                <Route 
                  path="/revenue"
                  element={<StatisticsOverviewRoute />}
                />
                <Route path="/transactions" element={<TransactionsRoute />} />
{/*
                <Route path="/revenue/:epoch" element={<RevenueRoute />} />
                <Route path="/revenue" element={<RevenueRoute />} />
*/}
                <Route
                  path="/proxies/:proxyId"
                  element={<ProxyDetailRoute />}
                />
                <Route path="/proxies" element={<ProxiesRoute />} />
                <Route
                  path="/login/callback"
                  element={<LoginCallbackRoute />}
                />
                <Route path="/login" element={<LoginRoute />} />
              </Routes>
            </Layout>
          </ThemeProvider>
        </AuthProvider>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
