import { Loader } from "@rigly/core";
import clsx from "clsx";
import Plotly from "react-plotly.js";
import getColorByIndex from "../utils/colors";
import { useThemeContext } from "../context/ThemeContext";

const defaultDatasetOptions = (i: number) => ({
  line: {
    color: getColorByIndex(i, 1),
    // shape: "spline",
    width: 3,
  },
  // fill: "tozeroy",
  // fillcolor: COLORS[i](0.2),
  showlegend: false,
});

export default function Plot({
  className,
  data,
  loading,
  loadingMessage,
  xaxis,
  yaxis,
  layout,
}: {
  className?: string;
  data: any[];
  loading: boolean;
  loadingMessage?: string;
  xaxis?: { [key: string]: any };
  yaxis?: { [key: string]: any };
  layout?: { [key: string]: any };
}) {
  const { theme } = useThemeContext();

  const isDataAvailable =
    data &&
    data.some((dataset) => dataset.x.length !== 0 && dataset.y.length !== 0);

  if (loading) {
    return (
      <div className={clsx(className)}>
        <div className="bg-zinc-100 dark:bg-zinc-900/50 w-full h-full flex items-center justify-center rounded-lg min-h-[40vh]">
          <div className="flex flex-col items-center justify-center gap-4">
            <Loader className="text-zinc-500 dark:text-zinc-300 w-6 h-6" />
            {loadingMessage && (
              <span className="text-zinc-500 dark:text-zinc-300 text-xs">
                {loadingMessage}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (!isDataAvailable) {
    return (
      <div className={clsx(className)}>
        <div className="bg-zinc-100 dark:bg-zinc-900/50 w-full h-full flex items-center justify-center rounded-lg min-h-[40vh]">
          <span className="text-zinc-500 dark:text-zinc-300 text-sm">
            No data available
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <Plotly
        className="w-full h-full"
        data={data.map((dataset: any, i: number) => {
          return { ...defaultDatasetOptions(i), ...dataset };
        })}
        layout={{
          margin: { t: 16, l: 68, r: 32, b: 32 },
          paper_bgcolor: "rgba(0,0,0,0)",
          plot_bgcolor: "rgba(0,0,0,0)",
          xaxis: {
            gridcolor: theme === "dark" ? "rgba(63,63,69,1)" : "#D4D4D8",
            tickfont: {
              color: theme === "dark" ? "white" : "rgba(82,82,91,1)",
            },
            ...xaxis,
          },
          yaxis: {
            gridcolor: theme === "dark" ? "rgba(63,63,69,1)" : "#D4D4D8",
            tickfont: {
              color: theme === "dark" ? "white" : "rgba(82,82,91,1)",
            },
            ...yaxis,
          },
          showlegend: false,
          barmode: layout?.barmode || 'group'
        }}
        config={{
          displayModeBar: false,
        }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}
