import { get, post, url } from "@rigly/core";
import toast from "react-hot-toast";
import { PayoutResolution } from "../types";
import { getToken } from "../utils/token";
import { getDefaultHeaders } from "../utils/headers";
import { BASE_API_URL } from "../constants";

export async function requestPayout(epoch: number) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    const res = await post(
      url(BASE_API_URL, "/api/sellers/payouts/request"),
      {
        headers: getDefaultHeaders(token),
      },
      {
        epoch,
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}


export async function getPayouts(
  limit: number,
  offset: number,
  resolution: PayoutResolution,
  teamId: number,
  epoch?: number,
  date?: any
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/payouts?resolution=${resolution}&limit=${limit || 8}&offset=${offset || 0}`;
    if (epoch !== undefined) {
      queryUrl += `&epoch=${epoch}`;
    }
    if (date !== undefined) {
      queryUrl += `&date=${date}`;
    }

    const res = await post(
      url(BASE_API_URL, queryUrl),
      {
        headers: getDefaultHeaders(token),
      },
      {
        team: teamId,
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}


export async function getPayoutsChart(
  resolution: PayoutResolution,
  teamId: number,
  epoch?: number,
  date?: any
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/payouts/chart?resolution=${resolution}&limit=8&offset=0`;
    if (epoch !== undefined) {
      queryUrl += `&epoch=${epoch}`;
    }
    if (date !== undefined) {
      queryUrl += `&date=${date}&type=worker`;
    }

    const res = await post(
      url(BASE_API_URL, queryUrl),
      {
        headers: getDefaultHeaders(token),
      },
      {
        team: teamId,
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}


export async function getPayoutsCSV(
  epoch?: number,
  teamId?: number,
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/payouts/csv`;

    const queryParams = []
    if (epoch !== undefined) {
      queryParams.push(`epoch=${epoch}`);
    }
    if (teamId !== undefined) {
      queryParams.push(`team=${teamId}`);
    }

    if (queryParams.length > 0) {
      queryUrl = [queryUrl, queryParams.join('&')].join('?')
    }

    const res = await get(
      url(BASE_API_URL, queryUrl),
      {
        headers: getDefaultHeaders(token),
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}
