import { get, post, url } from "@rigly/core";
import { getToken } from "../utils/token";
import { getDefaultHeaders } from "../utils/headers";
import { BASE_PROXY_API_URL } from "../constants";

interface Response {
  results: any[];
  count: number;
}

export async function getProxies(): Promise<Response> {
  const token = getToken();
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await get(url(BASE_PROXY_API_URL, "/api/v1/proxy/list"), {
    headers: getDefaultHeaders(token),
  });

  return res;
}

export async function getProxyLogs(proxyId: string) {
  const token = getToken();
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await fetch(
    url(BASE_PROXY_API_URL, `/api/v1/proxies/${proxyId}/logs`),
    {
      headers: getDefaultHeaders(token),
    }
  );

  const logs = await res.text();
  return logs;
}

export async function getProxy(proxyId: string) {
  const token = getToken();
  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await get(
    url(BASE_PROXY_API_URL, `/api/v1/proxy/list/${proxyId}`),
    {
      headers: getDefaultHeaders(token),
    }
  );

  return res;
}

export async function switchProxy(
  proxyId: string,
  data: {
    upstream_host: string;
    upstream_port: number;
    pool_username: string;
    order_id: number;
  }
) {
  const token = getToken();

  if (!token) {
    throw new Error("Invalid token");
  }

  const res = await post(
    url(BASE_PROXY_API_URL, `/api/v1/proxies/${proxyId}/switch`),
    {
      headers: getDefaultHeaders(token),
    },
    data
  );

  return res;
}
