import { useEffect, useState } from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import StatisticCard from "../components/StatisticCard";
import View from "../components/View";
import MonthlyRevenue from "../components/MontlyRevenue";
import { useAuthContext } from "../context/AuthContext";
import { getTotalHashrate } from "../api/statistics";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../constants";
import toast from "react-hot-toast";

type Statistic = {
  icon: string;
  title: string;
  description: string;
  value: any;
};

function Statistic() {
  const { team } = useAuthContext();

  const [isHashrateLoading, setIsHashrateLoading] = useState<boolean>(true);
  const [hashrate, setHashrate] = useState<number>(0);
  const [prevHashrate, setPrevHashrate] = useState<number>(0);

  const load = async () => {
    try {
      setIsHashrateLoading(true);
      // const res = await getTotalHashrate(team.id);
      // setHashrate(res.total_hashrate_ths);
      // setPrevHashrate(res.prev_total_hashrate_ths);
      setHashrate(0);
      setPrevHashrate(0);
    } catch (ex: any) {
      console.error(ex);
      // toast.error(ex.message, { position: "bottom-right" });
    } finally {
      setIsHashrateLoading(false);
    }
  };

  useEffect(() => {
    if (team) {
      load();
    }
  }, [team]);

  const increasePercentage =
    hashrate !== 0 && prevHashrate !== 0
      ? (hashrate - prevHashrate) / prevHashrate
      : 0;

  return (
    <StatisticCard
      className="col-span-12 lg:col-span-6 xl:col-span-3"
      icon="gauge"
      title="Hashrate"
      description={`+${(increasePercentage * 100).toFixed(1)}% from last month`}
      value={hashrate.toFixed(2)}
      suffix="TH/s"
      loadingPlaceholder="..."
      loading={isHashrateLoading}
    />
  );
}

export default function DashboardRoute() {
  const [statistics] = useState<Statistic[]>([
    {
      icon: "gauge",
      title: "-",
      value: "0.00",
      description: "+0.0% from last month",
    },
    {
      icon: "gauge",
      title: "-",
      value: "0.00",
      description: "+0.0% from last month",
    },
    {
      icon: "gauge",
      title: "-",
      value: "0.00",
      description: "+0.0% from last month",
    },
  ]);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Dashboard</title>
      </Helmet>
      <Topbar />
      <div className="flex min-h-screen-excluding-topbar">
        <View>
          <Sidebar />
          <div className="p-3 w-full flex flex-col lg:h-full lg:grid lg:grid-cols-12 lg:grid-rows-[1fr,1fr,1fr,1fr,1fr] gap-4">
            <Statistic />
            {statistics.map((statistic: Statistic, i: number) => (
              <StatisticCard
                key={i}
                className="col-span-12 lg:col-span-6 xl:col-span-3"
                icon={statistic.icon}
                title={statistic.title}
                description={statistic.description}
                value={statistic.value}
                loading={false}
                suffix="TH/s"
              />
            ))}
            <MonthlyRevenue className="col-span-12 row-span-2 xl:row-span-3" />
            {/* <RecentBids className="col-span-5 row-span-2 xl:row-span-3" /> */}
          </div>
        </View>
      </div>
    </div>
  );
}
