import { useThemeContext } from "../context/ThemeContext";
import { Toggle } from "@rigly/core";

export default function ThemeSwitcher() {
  const { theme, setTheme } = useThemeContext();

  return (
    <Toggle
      active={theme === "dark"}
      onChange={() => setTheme(theme === "dark" ? "light" : "dark")}
    />
  );
}
