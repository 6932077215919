export default function Logo({ className }: { className?: string }) {
  return (
    <svg
      width="53"
      height="16"
      viewBox="0 0 53 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.899994 15C0.753327 15 0.633327 14.9533 0.539994 14.86C0.446661 14.7667 0.399994 14.6467 0.399994 14.5V1.5C0.399994 1.35334 0.446661 1.23334 0.539994 1.14C0.633327 1.04667 0.753327 1 0.899994 1H6.33999C8.05999 1 9.41333 1.4 10.4 2.2C11.4 2.98667 11.9 4.10667 11.9 5.56C11.9 6.50667 11.6667 7.30667 11.2 7.96C10.7467 8.61334 10.1467 9.10667 9.39999 9.44L12.16 14.36C12.2 14.44 12.22 14.5133 12.22 14.58C12.22 14.6867 12.1733 14.7867 12.08 14.88C12 14.96 11.9 15 11.78 15H9.13999C8.88666 15 8.69999 14.94 8.57999 14.82C8.45999 14.6867 8.37333 14.5667 8.31999 14.46L6.03999 10.06H3.97999V14.5C3.97999 14.6467 3.93333 14.7667 3.83999 14.86C3.74666 14.9533 3.62666 15 3.47999 15H0.899994ZM3.97999 7.24H6.29999C6.92666 7.24 7.39333 7.08667 7.69999 6.78C8.01999 6.47334 8.17999 6.05334 8.17999 5.52C8.17999 5 8.02666 4.58 7.71999 4.26C7.42666 3.94 6.95333 3.78 6.29999 3.78H3.97999V7.24Z"
        fill="currentColor"
      />
      <path
        d="M13.7281 15C13.5815 15 13.4615 14.9533 13.3681 14.86C13.2748 14.7667 13.2281 14.6467 13.2281 14.5V1.5C13.2281 1.35334 13.2748 1.23334 13.3681 1.14C13.4615 1.04667 13.5815 1 13.7281 1H16.4081C16.5548 1 16.6748 1.04667 16.7681 1.14C16.8615 1.23334 16.9081 1.35334 16.9081 1.5V14.5C16.9081 14.6467 16.8615 14.7667 16.7681 14.86C16.6748 14.9533 16.5548 15 16.4081 15H13.7281Z"
        fill="currentColor"
      />
      <path
        d="M24.4925 15.2C23.2258 15.2 22.1325 14.9933 21.2125 14.58C20.3058 14.1533 19.5992 13.54 19.0925 12.74C18.5992 11.9267 18.3258 10.94 18.2725 9.78C18.2592 9.20667 18.2525 8.6 18.2525 7.96C18.2525 7.30667 18.2592 6.68667 18.2725 6.1C18.3258 4.96667 18.6058 4.00667 19.1125 3.22C19.6192 2.43334 20.3325 1.83334 21.2525 1.42C22.1725 1.00667 23.2525 0.800003 24.4925 0.800003C25.4925 0.800003 26.3792 0.92667 27.1525 1.18C27.9258 1.42 28.5725 1.74667 29.0925 2.16C29.6258 2.57334 30.0258 3.02667 30.2925 3.52C30.5725 4.01334 30.7192 4.5 30.7325 4.98C30.7458 5.1 30.7058 5.2 30.6125 5.28C30.5325 5.36 30.4325 5.4 30.3125 5.4H27.3925C27.2458 5.4 27.1325 5.38 27.0525 5.34C26.9858 5.28667 26.9258 5.21334 26.8725 5.12C26.7792 4.92 26.6392 4.71334 26.4525 4.5C26.2792 4.27334 26.0325 4.08 25.7125 3.92C25.4058 3.76 24.9992 3.68 24.4925 3.68C23.7325 3.68 23.1325 3.88 22.6925 4.28C22.2658 4.68 22.0325 5.32 21.9925 6.2C21.9525 7.34667 21.9525 8.50667 21.9925 9.68C22.0325 10.6133 22.2792 11.2867 22.7325 11.7C23.1858 12.1133 23.7858 12.32 24.5325 12.32C25.0392 12.32 25.4858 12.2333 25.8725 12.06C26.2725 11.8867 26.5858 11.62 26.8125 11.26C27.0392 10.8867 27.1525 10.4133 27.1525 9.84V9.46H25.2725C25.1258 9.46 24.9992 9.41334 24.8925 9.32C24.7992 9.21334 24.7525 9.08667 24.7525 8.94V7.46C24.7525 7.31334 24.7992 7.19334 24.8925 7.1C24.9992 6.99334 25.1258 6.94 25.2725 6.94H30.3125C30.4592 6.94 30.5792 6.99334 30.6725 7.1C30.7658 7.19334 30.8125 7.31334 30.8125 7.46V9.74C30.8125 10.8867 30.5525 11.8667 30.0325 12.68C29.5125 13.4933 28.7725 14.12 27.8125 14.56C26.8658 14.9867 25.7592 15.2 24.4925 15.2Z"
        fill="currentColor"
      />
      <path
        d="M32.607 15C32.4604 15 32.3404 14.9533 32.247 14.86C32.1537 14.7667 32.107 14.6467 32.107 14.5V1.5C32.107 1.35334 32.1537 1.23334 32.247 1.14C32.3404 1.04667 32.4604 1 32.607 1H35.247C35.3804 1 35.4937 1.04667 35.587 1.14C35.6937 1.23334 35.747 1.35334 35.747 1.5V11.98H41.787C41.9337 11.98 42.0537 12.0333 42.147 12.14C42.2537 12.2333 42.307 12.3533 42.307 12.5V14.5C42.307 14.6467 42.2537 14.7667 42.147 14.86C42.0537 14.9533 41.9337 15 41.787 15H32.607Z"
        fill="currentColor"
      />
      <path
        d="M44.8406 15C44.7073 15 44.5873 14.9533 44.4806 14.86C44.3873 14.7667 44.3406 14.6467 44.3406 14.5V10.2L39.7006 1.64C39.6873 1.6 39.674 1.56667 39.6606 1.54C39.6473 1.5 39.6406 1.46 39.6406 1.42C39.6406 1.31334 39.6806 1.22 39.7606 1.14C39.854 1.04667 39.9606 1 40.0806 1H42.5806C42.7806 1 42.934 1.05334 43.0406 1.16C43.1606 1.25334 43.234 1.34 43.2606 1.42L46.1406 6.58L49.0206 1.42C49.0606 1.34 49.134 1.25334 49.2406 1.16C49.3606 1.05334 49.5206 1 49.7206 1H52.2206C52.3406 1 52.4406 1.04667 52.5206 1.14C52.6006 1.22 52.6406 1.31334 52.6406 1.42C52.6406 1.46 52.634 1.5 52.6206 1.54C52.6206 1.56667 52.614 1.6 52.6006 1.64L47.9406 10.2V14.5C47.9406 14.6467 47.894 14.7667 47.8006 14.86C47.7073 14.9533 47.5873 15 47.4406 15H44.8406Z"
        fill="currentColor"
      />
    </svg>
  );
}
