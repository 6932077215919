import { useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { Card, List, Loader } from "@rigly/core";
import { Helmet } from "react-helmet";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { APP_NAME } from "../constants";
import { format } from "date-fns";
import { Transaction } from "../types";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import { useGetTransactions } from "../hooks/useGetTransactions";
import Toolbar from "../components/Toolbar";
import exportFromJSON from 'export-from-json'

export default function Transactions() {
  const { team, loading } = useAuthContext();
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(0);

  const { data, isLoading } = useGetTransactions({
    teamId: team?.id,
    activePage,
    limit,
  });

  const hanldeExportCSV = () => {
    const fileName = 'transactions'
    const exportType = exportFromJSON.types.csv
    exportFromJSON({ data: data.results, fileName, exportType })
  }

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Transactions</title>
      </Helmet>
      <Topbar />
      <div className="flex min-h-screen-excluding-topbar">
        <View className="bg-radial-gradient-dark dark:bg-radial-gradient-light">
          <Sidebar />
          <div className="p-3">
            <Card className="pt-2 p-4 items-start bg-radial-gradient-dark dark:bg-radial-gradient-light before:relative">
              <Toolbar className='!items-center !flex-row justify-between'>
                <Pagination
                  count={data?.count}
                  limit={limit}
                  setLimit={setLimit}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
                <button
                  onClick={hanldeExportCSV}
                  disabled={isLoading || Boolean(!data?.results.length)}
                  type="button"
                  className="rounded-md bg-blue-500 p-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Download csv
                </button>

              </Toolbar>
              {loading ? (
                <Loader />
              ) : (
                <List
                  loading={isLoading}
                  loadingMessage="Crunching data..."
                  data={data?.results || []}
                  className="w-full h-[40vh] min-h-[40vh]"
                  spanClass="text-white text-xs"
                  selected={() => false}
                  onClick={(e: any, tx: Transaction) => {
                    navigate(`/revenue/${tx.epoch}`);
                  }}
                  schema={[
                    {
                      label: "Epoch",
                      key: (tx: Transaction) => {
                        return (
                          <div className="flex items-center justify-start gap-1">
                            <span className="text-zinc-600 dark:text-white text-xs">
                              {tx.epoch}
                            </span>
                          </div>
                        );
                      },
                      type: "custom",
                    },
                    {
                      label: "Date",
                      type: "custom",
                      key: (tx: Transaction) => (
                        <span className="text-zinc-600 dark:text-white text-xs font-mono">
                          {format(new Date(tx.start_time), "dd/MM/yyyy")} -
                          {format(new Date(tx.end_time), "dd/MM/yyyy")}
                        </span>
                      ),
                    },
                    {
                      label: "Payout Amount",
                      type: "number",
                      key: (tx: Transaction) => (
                        <span className="text-zinc-600 dark:text-white text-xs font-mono">
                          {tx.payout_sats / 10 ** 8} BTC
                        </span>
                      ),
                    },
                    {
                      label: "TXID",
                      type: "custom",
                      key: (tx: Transaction) => {
                        return (
                          <span className="text-zinc-600 dark:text-white text-xs">
                            {tx.txid ?? "-"}
                          </span>
                        );
                      },
                    },
                    {
                      label: "Payout Addr.",
                      type: "custom",
                      key: (tx: Transaction) => {
                        return (
                          <span className="text-zinc-600 dark:text-white text-xs">
                            {tx.payout_address ?? "-"}
                          </span>
                        );
                      },
                    },
                  ]}
                />
              )}
            </Card>
          </div>
        </View>
      </div>
    </div>
  );
}
