import { SPOOF_EMAIL } from "../constants";

export function getDefaultHeaders(token?: string) {
  const headers: any = {};

  if (SPOOF_EMAIL) {
    headers["RiglySpoofEmail"] = SPOOF_EMAIL;
  }

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  headers["Content-Type"] = "application/json";

  return headers;
}
