import { createContext, useContext, useEffect, useState } from "react";
import { Theme, ThemeContextType } from "../types";
import * as LocalStorage from "../lib/local-storage";

const ThemeContext = createContext<ThemeContextType>({
  theme: "light",
  setTheme: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const initialTheme = window.matchMedia("(prefers-color-scheme: light)")
    .matches
    ? "light"
    : "dark";
  const [theme, setTheme] = useState(initialTheme);

  const handleChangeTheme = (value: Theme) => {
    if (value === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    LocalStorage.set("theme", value);
    setTheme(value);
  };

  const context = { theme, setTheme: handleChangeTheme } as ThemeContextType;

  useEffect(() => {
    const value = LocalStorage.get("theme");
    if (value) {
      handleChangeTheme(value as Theme);
    }
  }, []);

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
}
