import clsx from "clsx";

const Pagination = ({
  count,
  limit,
  setLimit,
  activePage,
  setActivePage,
}: {
  count: number;
  limit: number;
  setLimit: (limit: number) => void;
  activePage: number;
  setActivePage: (page: any) => void;
}) => {
  const pageCount = Math.ceil(count / limit);

  const getPages = () => {
    const elements = [];
    for (let i = 0; i < pageCount; i++) {
      elements.push(
        <div
          className={clsx(
            activePage === i && "bg-zinc-300 dark:bg-zinc-600",
            "cursor-pointer text-sm dark:bg-zinc-800 rounded border dark:border-zinc-700 w-7 h-7 flex items-center justify-center dark:hover:text-white outline-none",
            {
              "dark:text-white/30": activePage !== i,
              "dark:text-white": activePage === i,
            }
          )}
          onClick={() => setActivePage(i)}
          key={i}
        >
          {i + 1}
        </div>
      );
    }
    return elements;
  };

  return (
    <div className="flex items-center">
      <select
        className="dark:bg-zinc-800 border mr-4 dark:border-zinc-700 h-7 rounded text-sm dark:text-white px-2 w-15 outline-none text-center"
        value={limit}
        onChange={(e: any) => setLimit(e.target.value)}
        title="Select Limit"
      >
        <option value={8}>8</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      <div className="flex gap-1 p-1">
        <button
          disabled={activePage === 0}
          className={clsx(
            "cursor-pointer text-sm dark:bg-zinc-800 rounded border dark:border-zinc-700 w-7 h-7 dark:text-white/30 flex items-center justify-center dark:hover:text-white outline-none"
          )}
          onClick={() =>
            activePage !== 0 && setActivePage((page: number) => page - 1)
          }
        >
          &larr;
        </button>
        {getPages()}
        <button
          disabled={activePage === pageCount - 1}
          className={clsx(
            "cursor-pointer text-sm dark:bg-zinc-800 rounded border dark:border-zinc-700 w-7 h-7 dark:text-white/30 flex items-center justify-center dark:hover:text-white outline-none"
          )}
          onClick={() =>
            activePage !== pageCount &&
            setActivePage((page: number) => page + 1)
          }
        >
          &rarr;
        </button>
      </div>
      <select
        className="dark:bg-zinc-800 border dark:border-zinc-700 h-7 rounded text-sm dark:text-white px-2 w-15 outline-none text-center"
        value={limit}
        onChange={(e: any) => setLimit(e.target.value)}
        title="Select Limit"
      >
        <option value={8}>8</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
  );
};

export default Pagination;
