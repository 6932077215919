import clsx from 'clsx';
import { Button, Card, Icon } from '@rigly/core';
import { Link, useLocation } from 'react-router-dom';
import { IconType } from '../types';

const MENU_ITEMS = [
  { label: 'Dashboard', icon: 'gauge', href: '/' },
  { label: 'Revenue', icon: 'moneyBillWave', href: '/revenue' },
  { label: 'Transactions', icon: 'moneyBillWave', href: '/transactions' },
//  { label: 'Revenue', icon: 'moneyBillWave', href: '/revenue' },
  { label: 'Proxies', icon: 'gear', href: '/proxies' },
  { label: 'Settings', icon: 'gear', href: '/settings' },
];

export default function Sidebar() {
  const location = useLocation();

  return (
    <Card className="w-[40px] min-h-screen-excluding-topbar grow md:min-w-[200px] !p-0 !rounded-none border-l-0 border-t-0 border-b-0 border-r bg-radial-gradient-dark dark:bg-radial-gradient-light">
      <div className="flex flex-col">
        {MENU_ITEMS.map((item, i) => {
          const isActive =
            item.href === '/'
              ? location.pathname === item.href
              : location.pathname.startsWith(item.href);
          return (
            <Link key={i} to={item.href} className="w-full">
              <Button
                selected={isActive}
                className="w-10 justify-center md:justify-start flex md:w-full md:px-4 md:py-2 text-left border-0 !rounded-none !border-none group gap-4"
              >
                <Icon
                  icon={item.icon as IconType}
                  className={clsx(
                    'min-w-[14px] min-h-[14px] w-[14px] h-[14px]',
                    {
                      'text-zinc-700 dark:text-white': isActive,
                      'text-zinc-500': !isActive,
                    }
                  )}
                />
                <span
                  className={clsx(
                    'text-sm font-sans font-semibold hidden md:block',
                    {
                      'text-zinc-700 dark:text-white': isActive,
                      'text-zinc-500': !isActive,
                    }
                  )}
                >
                  {item.label}
                </span>
              </Button>
            </Link>
          );
        })}
      </div>
    </Card>
  );
}
