import { Loader } from "@rigly/core";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../constants";

export default function LoginCallbackRoute() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <Helmet>
        <title>{APP_NAME} - Sign In</title>
      </Helmet>
      <Loader className="text-white" />
    </div>
  );
}
