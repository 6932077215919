import { Helmet } from "react-helmet";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { APP_NAME } from "../constants";
import { useEffect, useState } from "react";
import * as api from "../api/proxies";
import toast from "react-hot-toast";
import clsx from "clsx";
import { Card } from "@rigly/core";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Proxy, WorkerMachine } from "../types";
import { calculateEfficiency } from "../utils/hashrate";
import Toolbar from "../components/Toolbar";
import Modal from "../components/Modal";
import { parsePoolUrl } from "../utils/pool";

interface Log {
  timestamp: string;
  type: string;
  pid: string;
  content: string;
}

export default function Proxies() {
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [proxy, setProxy] = useState<Proxy | undefined>(undefined);
  const [logs, setLogs] = useState<string | undefined>(undefined);
  const [logsQuery, setLogsQuery] = useState<string | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>("logs");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [poolOne, setPoolOne] = useState<string | undefined>(undefined);
  const [poolUserOne, setPoolUserOne] = useState<string | undefined>(undefined);
  const [poolPasswordOne, setPoolPasswordOne] = useState<string | undefined>(
    undefined
  );

  const loadProxy = async (isRefresh: boolean) => {
    const proxyId = params.proxyId;
    if (!proxyId) {
      return;
    }

    try {
      setLoading(true);
      const proxyRes: Proxy = await api.getProxy(proxyId);
      const logsRes = await api.getProxyLogs(proxyId);
      setProxy(proxyRes);
      setLogs(logsRes);

      if (!isRefresh && proxyRes.upstream) {
        setPoolOne(proxyRes.upstream.pool_user.pool);
        setPoolUserOne(proxyRes.upstream.pool_user.username);
      }
    } catch (ex: any) {
      console.log(ex.message);
      toast.error(ex.message, { position: "bottom-right" });
    }
    {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const proxyId = params.proxyId;
    if (!proxyId) {
      return;
    }

    if (!poolOne) {
      toast.error("Invalid pool", { position: "bottom-right" });
      return;
    }

    if (!poolUserOne) {
      toast.error("Invalid pool username", { position: "bottom-right" });
      return;
    }

    try {
      const { host, port } = parsePoolUrl(poolOne);

      const res = await api.switchProxy(proxyId, {
        upstream_host: host,
        upstream_port: port,
        pool_username: poolUserOne,
        order_id: 111,
      });

      console.log(res);
      toast.success("Switched proxy", { position: "bottom-right" });
    } catch (ex: any) {
      toast.error(ex.toString(), { position: "bottom-right" });
    }
  };

  const handleSearchLogs = (value: string) => {
    setLogsQuery(value);
  };

  useEffect(() => {
    loadProxy(false);
  }, [params]);

  useEffect(() => {
    const interval = setInterval(() => loadProxy(true), 1000 * 10);
    return () => {
      clearInterval(interval);
    };
  }, [params]);

  const formattedLogs = logs
    ?.split("\n")
    .filter((line: string) => {
      if (!logsQuery) {
        return true;
      }
      return line.indexOf(logsQuery) !== -1;
    })
    .filter((line) => line && line.indexOf(":proxy.py: ") !== -1)
    .map((line) => {
      const timestamp = line.split(":proxy.py: ")[0];
      const pid = line.split(":proxy.py: ")[1].split(":")[0].trim();
      const type = line.split(pid)[1].split(":")[1].trim();
      const content = line.split(`${type}:`)[1].trim();

      return {
        timestamp,
        type,
        pid,
        content,
      };
    });

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Proxies</title>
      </Helmet>
      <Topbar />
      <div className="grid grid-cols-[200px_1fr] min-h-screen-excluding-topbar w-full">
        <View className="bg-radial-gradient-dark dark:bg-radial-gradient-light">
          <Sidebar />
          <div className="p-3">
            <Toolbar>
              <span className="text-sm text-white ml-2">
                Proxy {proxy ? proxy.name : "???"}
              </span>
              <button
                className="h-7 rounded bg-blue-600 border border-blue-400 px-3 cursor-pointer hover:bg-blue-400 hover:border-blue-300"
                onClick={() => setIsModalVisible(true)}
              >
                <span className="text-sm text-white">Switch</span>
              </button>
            </Toolbar>
            <div className="grid grid-cols-6 gap-3">
              <div className="col-span-4 flex flex-col gap-3">
                <Card className="!p-0">
                  <div className="flex border-b border-zinc-800 justify-between items-center">
                    <div className="flex items-center justify-start">
                      <button
                        onClick={() => setActiveTab("chart")}
                        className={clsx("text-sm h-10 px-3", {
                          "text-white": activeTab === "chart",
                          "text-white/50": activeTab !== "chart",
                        })}
                      >
                        Chart
                      </button>
                      <button
                        onClick={() => setActiveTab("logs")}
                        className={clsx("text-sm h-10 px-3", {
                          "text-white": activeTab === "logs",
                          "text-white/50": activeTab !== "logs",
                        })}
                      >
                        Logs
                      </button>
                    </div>
                    {activeTab === "logs" && (
                      <input
                        type="text"
                        className="mr-2 outline-none h-7 px-2 bg-zinc-800 border-zinc-700 border rounded text-sm text-white"
                        placeholder="Search..."
                        onInput={(e: any) => handleSearchLogs(e.target.value)}
                      />
                    )}
                  </div>
                  {activeTab === "chart" && (
                    <div className="h-[40vh] flex items-center justify-center">
                      <span className="text-xs text-white/40">
                        No chart data available
                      </span>
                    </div>
                  )}
                  {activeTab === "logs" && (
                    <div className="p-2 rounded whitespace-nowrap overflow-auto w-full h-[40vh]">
                      {formattedLogs && (
                        <>
                          {formattedLogs.length > 0 &&
                            formattedLogs.map((log: Log, i: number) => {
                              return (
                                <span
                                  className="text-white text-sm font-mono flex gap-1"
                                  key={i}
                                >
                                  <span className="inline">
                                    {format(
                                      new Date(log.timestamp),
                                      "dd/MM/yyyy HH:mm:ss"
                                    )}
                                  </span>
                                  <span className="inline text-white/50">
                                    [{log.pid}]
                                  </span>
                                  <span
                                    className={clsx("inline", {
                                      "text-blue-400/70": log.type === "INFO",
                                      "text-purple-400/70":
                                        log.type === "DEBUG",
                                      "text-red-400/70":
                                        log.type === "ERROR" ||
                                        log.type === "CRITICAL",
                                    })}
                                  >
                                    {log.type}
                                  </span>
                                  <span className="inline text-white">
                                    {log.content}
                                  </span>
                                </span>
                              );
                            })}
                          {formattedLogs.length === 0 && (
                            <div className="flex items-center justify-center">
                              <span className="text-sm text-white/40">
                                No logs found
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Card>
                <Card className="!p-0 col-span-4 row-span-1">
                  <div className="px-3 h-10 flex items-center justify-start">
                    <span className="text-white text-sm">Machines</span>
                  </div>
                  {proxy && (
                    <div className="flex flex-col px-3 pb-3">
                      {proxy.workers.length === 0 && (
                        <div className="flex justify-center items-center h-[160px]">
                          <span className="text-white/50 text-sm">
                            No machines detected
                          </span>
                        </div>
                      )}
                      {proxy.workers.length > 0 && (
                        <table>
                          <thead>
                            <tr>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  #
                                </span>
                              </th>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  Machine
                                </span>
                              </th>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  Downstream
                                </span>
                              </th>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  Hashrate
                                </span>
                              </th>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  Shares
                                </span>
                              </th>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  Difficulty
                                </span>
                              </th>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  Efficiency
                                </span>
                              </th>
                              <th className="text-left pb-2">
                                <span className="text-white text-xs font-normal">
                                  Last share
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {proxy.workers.map(
                              (worker: WorkerMachine, i: number) => {
                                return (
                                  <tr key={i} className="">
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-white">
                                        {i + 1}
                                      </span>
                                    </td>
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-white">
                                        {worker.name}
                                      </span>
                                    </td>
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-white">
                                        {worker.host}:{worker.port}
                                      </span>
                                    </td>
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-white">
                                        {worker.hashrate?.average
                                          ? `${(
                                              worker.hashrate.average /
                                              1000000000000
                                            ).toFixed(2)} TH/s`
                                          : "-"}
                                      </span>
                                    </td>
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-white">
                                        {worker.shares
                                          ? `${worker.shares.accepted} / ${worker.shares.rejected}`
                                          : "-"}
                                      </span>
                                    </td>
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-white">
                                        {worker.difficulty}
                                      </span>
                                    </td>
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-white">
                                        {calculateEfficiency(worker.shares)}%
                                      </span>
                                    </td>
                                    <td className="text-left py-1 border-t border-zinc-800">
                                      <span className="text-sm text-green-500">
                                        Accepted
                                      </span>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </Card>
              </div>
              <Card className="!p-0 col-span-2 row-span-3 flex flex-col">
                {proxy && (
                  <>
                    {[
                      {
                        label: "Host",
                        value: `${proxy.host}:${proxy.port}`,
                      },
                      {
                        label:
                          proxy.workers.length === 1 ? "Worker" : "Workers",
                        value: proxy.workers.length,
                      },
                      {
                        label: "Upstream Pool",
                        value: proxy.upstream
                          ? proxy.upstream.pool_user.pool
                          : "-",
                      },
                      {
                        label: "Upstream User",
                        value: proxy.upstream
                          ? proxy.upstream.pool_user.username
                          : "-",
                      },
                      {
                        label: "Hashrate",
                        value: proxy.hashrate.average
                          ? `${(proxy.hashrate.average / 1000000000000).toFixed(
                              2
                            )} TH/s`
                          : "-",
                      },
                      {
                        label: "Efficiency",
                        value: `${calculateEfficiency(proxy.shares)}%`,
                      },
                      {
                        label: "Difficulty",
                        value: 0,
                      },
                    ].map((stat: any, i: number) => {
                      return (
                        <div
                          key={i}
                          className="flex flex-col p-3 border-b border-zinc-800"
                        >
                          <span className="text-white/50 text-sm">
                            {stat.label}
                          </span>
                          <span className="text-white text-sm">
                            {stat.value}
                          </span>
                        </div>
                      );
                    })}
                  </>
                )}
              </Card>
            </div>
          </div>
        </View>
      </div>
      <Modal visible={isModalVisible}>
        <Modal.Header>
          <span>Switch Proxy</span>
          <button onClick={() => setIsModalVisible(false)}>x</button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3 min-w-[60vw]">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left font-normal text-sm pb-2 pr-1.5">
                    Pool
                  </th>
                  <th className="text-left font-normal text-sm pb-2 px-1.5">
                    User
                  </th>
                  <th className="text-left font-normal text-sm pb-2 pl-1.5">
                    Password
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pr-1.5">
                    <input
                      className="w-full outline-none h-10 px-3 bg-zinc-800 border-zinc-700 border rounded"
                      type="text"
                      name="pool_one"
                      placeholder="Pool #1"
                      value={poolOne}
                      onInput={(e: any) => setPoolOne(e.target.value)}
                    />
                  </td>
                  <td className="px-1.5">
                    <input
                      className="w-full outline-none h-10 px-3 bg-zinc-800 border-zinc-700 border rounded"
                      type="text"
                      name="user_one"
                      placeholder="User #1"
                      value={poolUserOne}
                      onInput={(e: any) => setPoolUserOne(e.target.value)}
                    />
                  </td>
                  <td className="pl-1.5">
                    <input
                      className="w-full outline-none h-10 px-3 bg-zinc-800 border-zinc-700 border rounded"
                      type="text"
                      name="password_one"
                      placeholder="Password #1"
                      value={poolPasswordOne}
                      onInput={(e: any) => setPoolPasswordOne(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div />
          <button
            className="h-7 rounded bg-blue-600 border border-blue-400 px-3 cursor-pointer hover:bg-blue-400 hover:border-blue-300"
            onClick={handleSave}
          >
            <span className="text-sm text-white">Save</span>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
