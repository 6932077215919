import clsx from "clsx";
import { Card, Icon } from "@rigly/core";

import Chart from "./Chart";
import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { getMonthlyRevenue } from "../api/statistics";

export default function MonthlyRevenue({ className }: { className?: string }) {
  const { team } = useAuthContext();

  const [revenue, setRevenue] = useState<any>(undefined);

  const load = async () => {
    const res = getMonthlyRevenue(team.id);
    setRevenue(res);
  };

  useEffect(() => {
    if (team) {
      load();
    }
  }, [team]);

  return (
    <Card
      className={clsx(
        "p-8 bg-radial-gradient-dark dark:bg-radial-gradient-light",
        className
      )}
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-start">
          <span className="text-zinc-500 dark:text-white text-sm mb-2">
            Monthly Revenue
          </span>
          <Icon icon="sats" className="w-4 h-4 text-white/50" />
        </div>
        <span className="text-zinc-500 dark:text-white/50 text-xs mb-4">
          You revenue for the past month.
        </span>
        <Chart data={revenue} />
      </div>
    </Card>
  );
}
