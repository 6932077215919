import { get, url } from '@rigly/core';
import toast from 'react-hot-toast';
import { getToken } from '../utils/token';
import { getDefaultHeaders } from '../utils/headers';
import { BASE_API_URL } from '../constants';

export async function getTransactions({
  teamId,
  limit,
  offset,
}: {
  teamId: number;
  limit: number;
  offset: number;
}) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('Invalid token');
    }

    const queryUrl = `/api/sellers/transactions?team=${teamId}&limit=${limit}&offset=${offset}`;

    const res = await get(url(BASE_API_URL, queryUrl), {
      headers: getDefaultHeaders(token),
    });

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: 'bottom-right' });
  }
}
