import { useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { Button, Card, Icon, Popover, SelectField } from "@rigly/core";
import ThemeSwitcher from "./ThemeSwitcher";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import DefaultAvatar from "../images/default-avatar.png";
import { Team } from "../types";
import { RIGLY_TEAM_ID } from "../constants";

export default function Topbar() {
  const navigate = useNavigate();
  const { team, setTeam, teams } = useAuthContext();
  const { account, loading: accountLoading, logout } = useAuthContext();
  const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false);

  return (
    <Card className="rounded-none border-t-0 border-x-0 flex !flex-row justify-between !p-0 h-[50px] overflow-visible items-center">
      <Link
        to="/"
        className="group flex gap-[6px] h-full items-center px-4 min-w-[212px]"
      >
        <Logo className="text-zinc-600 dark:text-white" />
        <span className="text-zinc-800 dark:text-white/40 group-hover:text-zinc-400 group-hover:dark:text-white/60 font-bold text-[10px] mt-[1px] uppercase tracking-widest transition-all duration-200">
          Sellers
        </span>
      </Link>
      <SelectField
        id="team-selector"
        className="mr-auto"
        value={team}
        onChange={(team: Team) => {
          window.localStorage.setItem(RIGLY_TEAM_ID, team.id.toString());
          setTeam(team);
        }}
        selected={(team: Team) => (
          <>
            <div className="w-5 h-5 rounded border border-zinc-400 dark:border-zinc-600 bg-zinc-400 dark:bg-zinc-700 overflow-hidden">
              <img src={team.logo} />
            </div>
            <span className="text-sm text-zinc-500 dark:text-white font-bold">
              {team.name}
            </span>
          </>
        )}
      >
        {teams.map((t: Team, i: number) => {
          return (
            <SelectField.Option
              key={i}
              value={t}
              render={(onSelect?: (index: any) => void, value?: any) => {
                return (
                  <Button
                    className="py-2 px-4 h-auto w-full border-0 flex gap-4 rounded-none justify-between"
                    onClick={() => {
                      if (typeof onSelect === "function") {
                        onSelect(value);
                      }
                    }}
                  >
                    <div className="rounded border border-zinc-300 dark:border-zinc-600 bg-zinc-400 dark:bg-zinc-900 w-10 h-10 overflow-hidden">
                      <img src={t.logo} />
                    </div>
                    <div className="flex flex-col items-start justify-center mr-auto">
                      <span className="text-lg font-bold text-zinc-600 dark:text-zinc-400">
                        {t.name}
                      </span>
                      <span className="text-sm text-white/30">
                        {`${t.slug}.rigly.io` ?? "-"}
                      </span>
                    </div>
                    {t.id === team.id && (
                      <div className="w-2 h-2 bg-blue-500 border border-blue-300 ml-2 rounded-full" />
                    )}
                  </Button>
                );
              }}
            />
          );
        })}
      </SelectField>
      <div className="flex items-center justify-end gap-4 h-full">
        <ThemeSwitcher />
        {!accountLoading && !account && (
          <div>
            <a href="/login">
              <Button>Sign in</Button>
            </a>
          </div>
        )}
        {!accountLoading && account && (
          <>
            <Button
              id="account"
              className="border-none px-3 h-full rounded-none"
              onClick={() => setShowAccountMenu(true)}
            >
              <div className="w-6 h-6 aspect-square rounded-full overflow-hidden bg-zinc-300 dark:bg-zinc-700 border border-zinc-400 dark:border-zinc-600">
                <img src={account.avatar || DefaultAvatar} />
              </div>
              <span>{account.email}</span>
            </Button>
            <Popover
              active={showAccountMenu}
              onClose={() => setShowAccountMenu(false)}
              targetId="account"
              className="p-0 w-[140px] z-10"
            >
              <div className="flex flex-col">
                {[
                  {
                    label: "Settings",
                    icon: "gear" as any,
                    onClick: () => {
                      navigate("/settings");
                      setShowAccountMenu(false);
                    },
                  },
                  {
                    label: "Sign out",
                    icon: "rightFromBracket" as any,
                    onClick: () => {
                      logout();
                      setShowAccountMenu(false);
                    },
                  },
                ].map((item, i) => (
                  <Button
                    key={i}
                    className="px-3 border-none text-left rounded-none first-of-type:rounded-t last-of-type:rounded-b"
                    onClick={item.onClick}
                  >
                    <Icon icon={item.icon} className="w-4 h-4" />
                    <span dangerouslySetInnerHTML={{ __html: item.label }} />
                  </Button>
                ))}
              </div>
            </Popover>
          </>
        )}
      </div>
    </Card>
  );
}
