import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as auth from "../api/auth";
import { Account, AuthContextType, Team } from "../types";
import { AUTH_TOKEN, RIGLY_TEAM_ID } from "../constants";
import toast from "react-hot-toast";

const AuthContext = createContext<AuthContextType>({
  account: undefined,
  token: undefined,
  setAccount: () => {},
  login: () => Promise.resolve({ message: "" }),
  logout: () => {},
  authorize: () => {},
  loading: true,
  teams: [],
  team: undefined,
  setTeam: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined | null>(undefined);
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [teams, setTeams] = useState<Team[]>([]);
  const [team, setTeam] = useState<Team | undefined>(undefined);

  const authorize = async (code: string, email: string) => {
    try {
      setLoading(true);
      const res = await auth.authorize(code, email);
      setToken(res.token);
      window.localStorage.setItem(AUTH_TOKEN, res.token);
    } catch (ex: unknown) {
      setToken(null);
    } finally {
      setLoading(false);
    }
  };

  const checkSession = async () => {
    try {
      const res = await auth.getAccount();
      if (!res) {
        if (location.pathname !== "/login") {
          navigate("/login");
        }
        return;
      }

      if (res.account.type === "buyer" && !res.account.is_staff) {
        if (res.account.is_staff) {
          console.log("[auth] logout prevented because account is staff");
        }

        return logout();
      }

      const teamsRes = await auth.getTeams();
      if (!res) {
        toast.error("Could not load teams", { position: "bottom-right" });
      }

      setAccount(res.account);
      setTeams(teamsRes);

      const selectedTeamIdRaw = window.localStorage.getItem(RIGLY_TEAM_ID);
      if (selectedTeamIdRaw) {
        const selectedTeamId = Number(selectedTeamIdRaw);
        setTeam(teamsRes.find((t: Team) => t.id === selectedTeamId));
      } else {
        setTeam(teamsRes[0]);
      }

      if (location.pathname === "/login/callback") {
        navigate("/");
      }
    } catch (ex: any) {
      console.log(ex);
      setToken(undefined);
      setAccount(undefined);
      window.localStorage.removeItem(AUTH_TOKEN);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  const login = async (
    email: string,
    returnUrl?: string
  ): Promise<{ message: string }> => {
    const res = await auth.login(email, returnUrl);
    return res;
  };

  const logout = () => {
    setToken(undefined);
    setAccount(undefined);
    window.localStorage.removeItem(AUTH_TOKEN);
    navigate("/login");
  };

  useEffect(() => {
    const email = searchParams.get("email");
    const code = searchParams.get("code");

    if (code && email) {
      authorize(code, email);
    }
  }, [searchParams]);

  useEffect(() => {
    if (token) {
      checkSession();
    } else {
      const token = window.localStorage.getItem(AUTH_TOKEN);

      if (token) {
        setToken(token);
      } else {
        navigate("/login");
      }
    }
  }, [token]);

  const context = {
    account,
    team,
    setTeam,
    teams,
    setAccount,
    token,
    login,
    authorize,
    logout,
    loading,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
