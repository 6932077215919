import { Helmet } from "react-helmet";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { APP_NAME } from "../constants";
import { useEffect, useState } from "react";
import * as api from "../api/proxies";
import toast from "react-hot-toast";
import { Proxy } from "../types";
import { Card } from "@rigly/core";
import { Link } from "react-router-dom";
import Toolbar from "../components/Toolbar";
import { parsePoolUrl } from "../utils/pool";

export default function Proxies() {
  const [loading, setLoading] = useState<boolean>(true);
  const [proxies, setProxies] = useState<Proxy[]>([]);
  const [total, setTotal] = useState<number>(0);

  const loadProxies = async () => {
    try {
      setLoading(true);
      const res = await api.getProxies();
      setProxies(res.results);
      setTotal(res.count);
    } catch (ex: any) {
      console.log(ex.message);
      toast.error(ex.message, { position: "bottom-right" });
    }
    {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProxies();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => loadProxies(), 1000 * 10);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Proxies</title>
      </Helmet>
      <Topbar />
      <div className="grid grid-cols-[200px_1fr] min-h-screen-excluding-topbar">
        <Sidebar />
        <div className="p-3 flex flex-col gap-4">
          <Toolbar className="border border-zinc-700 rounded p-2">
            <button className="bg-zinc-700 text-white rounded px-3 h-7 border border-zinc-600 cursor-pointer outline-none hover:bg-zinc-600 hover:border-zinc-500">
              <span className="text-xs text-white font-bold">Create</span>
            </button>
          </Toolbar>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {proxies.map((proxy: Proxy, i: number) => {
              return (
                <Link to={`/proxies/${proxy.id}`}>
                  <Card key={i}>
                    <span className="text-white font-bold">
                      {proxy.name} ({proxy.id})
                    </span>

                    <span className="text-white/50 text-xs">
                      {proxy.host ?? "-"}:{proxy.port ?? "-"} &rarr;{" "}
                      {proxy.upstream
                        ? parsePoolUrl(proxy.upstream.pool_user.pool).location
                        : "No workers"}
                    </span>

                    <div className="h-[200px] w-full border border-zinc-800 mb-6 mt-4 rounded-lg flex items-center justify-center">
                      <span className="text-xs text-white/40">
                        No chart data available
                      </span>
                    </div>

                    <div>
                      <div className="grid grid-cols-3">
                        {[
                          {
                            value: proxy.hashrate.average
                              ? `${(
                                  proxy.hashrate.average / 1000000000000
                                ).toFixed(2)} TH/s`
                              : "-",
                            label: "Hashrate",
                          },
                          {
                            value: 0,
                            label: "Accepted",
                          },
                          {
                            value: 0,
                            label: "Rejected",
                          },
                        ].map((stat: any, i: number) => {
                          return (
                            <div className="flex flex-col" key={i}>
                              <span className="text-white/70 text-sm font-mono">
                                {stat.value}
                              </span>
                              <span className="text-white/40 text-xs">
                                {stat.label}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="grid grid-cols-3 border-t border-zinc-800 mt-3 pt-3">
                        {[
                          {
                            value: proxy.workers.length,
                            label:
                              proxy.workers.length === 1 ? "Worker" : "Workers",
                          },
                          {
                            value: proxy.difficulty ?? "-",
                            label: "Difficulty",
                          },
                          {
                            value: `${95.12}%`,
                            label: "Efficiency",
                          },
                        ].map((stat: any, i: number) => {
                          return (
                            <div className="flex flex-col" key={i}>
                              <span className="text-white/70 text-sm font-mono">
                                {stat.value}
                              </span>
                              <span className="text-white/40 text-xs">
                                {stat.label}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Card>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
