// const COLORS = [
//   (opacity: number) => `rgba(59, 129, 246, ${opacity})`,
//   (opacity: number) => `rgba(248, 115, 21, ${opacity})`,
//   (opacity: number) => `rgba(168, 84, 247, ${opacity})`,
//   (opacity: number) => `rgba(33, 197, 93, ${opacity})`,
//   (opacity: number) => `rgba(239, 68, 68, ${opacity})`,
//   (opacity: number) => `rgba(59, 129, 246, ${opacity})`,
//   (opacity: number) => `rgba(248, 115, 21, ${opacity})`,
//   (opacity: number) => `rgba(168, 84, 247, ${opacity})`,
//   (opacity: number) => `rgba(33, 197, 93, ${opacity})`,
//   (opacity: number) => `rgba(239, 68, 68, ${opacity})`,
// ];

// export default function getPlotColor(index: number, opacity: number) {
//   return COLORS[index](opacity);
// }

export default function getColorByIndex(index: number, opacity: number) {
  if (index === 0) {
    return `rgba(59, 129, 246, ${opacity})`;
  }
  const hue = (index * 137.508) % 360;
  const saturation = 50; // Adjust the saturation (0-100)
  const lightness = 50; // Adjust the lightness (0-100)
  return `rgba(${hslToRgb(hue, saturation, lightness)}, ${opacity})`;
}

function hueToRgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function hslToRgb(h: number, s: number, l: number) {
  h /= 360;
  s /= 100;
  l /= 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l; // Achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return `${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(
    b * 255
  )}`;
}
