import { url, get, post } from "@rigly/core";
import {
  AuthorizeResponse,
  GetAccountResponse,
  LoginResponse,
} from "../types/response";
import { getToken } from "../utils/token";
import { getDefaultHeaders } from "../utils/headers";
import { BASE_API_URL, BASE_PORTAL_URL } from "../constants";

export async function login(
  email: string,
  returnUrl?: string
): Promise<LoginResponse> {
  const res = await post(
    url(BASE_API_URL, "/api/auth/login"),
    {},
    {
      email: email,
      url: BASE_PORTAL_URL,
      return_url: returnUrl,
    }
  );

  return res;
}

export async function authorize(
  code: string,
  email: string
): Promise<AuthorizeResponse> {
  const res = await post(
    url(BASE_API_URL, "/api/auth/authorize"),
    {},
    {
      code,
      email,
    }
  );

  return res;
}

export async function getAccount(): Promise<GetAccountResponse | undefined> {
  const token = getToken();
  if (!token) {
    console.log("Could not get account, invalid token");
    return;
  }

  const res = await get(url(BASE_API_URL, "/api/account"), {
    headers: getDefaultHeaders(token),
  });

  return { account: res.account, token };
}

export async function getTeams() {
  const token = getToken();
  if (!token) {
    console.log("Could not get account, invalid token");
    return;
  }

  const res = await get(url(BASE_API_URL, "/api/sellers/teams"), {
    headers: getDefaultHeaders(token),
  });

  return res;
}
