import clsx from "clsx";

export default function Chart({
  className,
}: // data,
{
  className?: string;
  data: { data: any; xaxis: any; yaxis: any };
}) {
  return (
    <div
      className={clsx(
        className,
        "w-full h-full  rounded-lg border border-zinc-300 dark:border-zinc-700"
      )}
    >
      <div className="flex items-center justify-center w-full h-full min-h-[120px]">
        <span className="text-zinc-500 dark:text-zinc-300 text-xs">
          No data available
        </span>
      </div>
    </div>
  );
}
