import { post, url } from "@rigly/core";
import toast from "react-hot-toast";
import { getToken } from "../utils/token";
import { getDefaultHeaders } from "../utils/headers";
import { BASE_API_URL } from "../constants";

export async function updateTeam(teamId: any, data: { [key: string]: any }) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    await post(
      url(BASE_API_URL, "/api/sellers/teams/update"),
      {
        headers: getDefaultHeaders(token),
      },
      { team: teamId, ...data }
    );
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}
